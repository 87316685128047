/** @jsx jsx */
import HomePageLayout from '../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';

export default function TextStyles({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Mietoptççion - Haus verkaufen und darin wohnen bleiben"
      description="Egal wie auch immer die Umstände sind, mit unserer Mietoption können Sie beim Verkauf Ihrer Immobilien flexibel bleiben."
      keywords={['Mietoption']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Ich bin ein Eye Catcher"
        subtitle="der einfach nur neugiereig machen soll"
        image={data.hero}
      />
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>Heading Division</Heading>
          <Subheading>Division Subheadline</Subheading>
        </div>
        <h1> H1 heading (40px)</h1>
        <h2> H2 heading (32px)</h2>
        <h3> H3 heading (28px)</h3>
        <h4> H4 heading (24px)</h4>
        <h5> H5 heading (20px)</h5>
        <h6> H6 heading (16px)</h6>
        <p>
          Back in early 2013, I was working at a small startup in San Francisco
          called Stitch Fix, alongside Adam Morse and a handful of others. We
          were designing and building out early versions of their marketing
          pages and some internal tools. I had the word designer in my title,
          and until that point in my career, I'd not shipped much production
          code for work. I learned Flash in college and taught myself HTML and
          CSS on the side over the years, using it for small side projects and
          design prototypes for user research, but had never used Git and knew
          next-to-nothing about JavaScript.
        </p>
        <p>
          We were both enamored by the work that people like Nicole Sullivan and
          Nicolas Gallagher were doing with Object-Oriented CSS and stealing
          lots of ideas for what we were working on. After we left the company,
          I decided to move back to the east coast and work for Kickstarter.
        </p>
        <p>
          In late 2013, I'd taken some of the ideas Adam and I were talking
          about at the time, and some of the problems we were working on at
          Kickstarter (involving a 1MB+ CSS bundle), and released the first
          version of Basscss. Around the same time, Adam released Tachyons. We
          both continued to develop each project separately and never landed on
          a common API for the two libraries.
        </p>
        <p>
          <strong>Back in early 2013, I was working at a small</strong>
        </p>

        <p>List:</p>
        <ul>
          <li>Back in early 2013, I was working at a small</li>
          <li>startup in San Francisco called Stitch Fix,</li>
          <li>alongside Adam Morse and a handful of others.</li>
          <li>We were designing and building out early versions of</li>
          <li>
            their marketing pages and some internal tools. I had the word
            designer in my
          </li>
          <li>
            title, and until that point in my career, I'd not shipped much
            production code
          </li>
          <li>
            for work. I learned Flash in college and taught myself HTML and CSS
            on the side over
          </li>
          <li>
            the years, using it for small side projects and design prototypes
            for user research,
          </li>
          <li>
            but had never used Git and knew next-to-nothing about JavaScript.
          </li>
        </ul>
      </Section>
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-home" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
