/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Subheading as styles } from './Subheading.styles';

export default function Subheading({ children, ...rest }) {
  return (
    <h3 {...rest} sx={{ ...styles }}>
      {children}
    </h3>
  );
}
